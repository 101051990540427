import notification from './components/notification'
export default {
    name: "top-nav",
    components:{ notification },
    props: {
        // 系统标题
        navTittle: {
            type: String,
            default: '亿网充'
        },
        // Tab页名称
        tabTittle: {
            type: String,
            default: '设备管理'
        },
        // 导航开关
        openState: {
            type: Boolean,
            default: true
        },
        userName: {
            type: String,
            default: 'admin'
        },
        tabList: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            userHead: require('@/assets/user-head.png'),
        }
    },
    computed:{
        bizType(){
            return this.$store.state.bizType === 0;
        },
        bizTypes(){
            return this.$store.state.bizTypes.length === 2;
        }
    },
    methods: {
        changeTabHandler(item, index) {
            this.$emit('changeTabHandler', item, index)
        },
        changeTabIndex(index) {
            this.$refs.insideTab.changeinsideTab(index);
        },
        // 下拉框的选项事件
        handleCommand(command) {
            switch (command) {
                case 'return':
                    this.$emit('clickUserButton');
                    break;
                case 'changePassword':
                    this.$emit('clickModifyPwd')
                    break;
            }
        },
        // 切换系统
        switchingPlatforms(){
            this.$emit('switchingPlatforms')
        },
        ready() {
            this.$emit('ready')
        }
    }
}
