<template>
  <el-dialog
      width="50%"
      :visible.sync="dialogSystemPopVisible">
    <div class="systemSelectsPop">
      <p>请选择你要进入的平台</p>
      <div class="card-body">
        <div class="card" v-for="item in systemList" :key="item.type" @click="openSystem(item.type)">
          <img :src="item.url">
          <span v-text="item.name"></span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "systemSelectsPop",
  data() {
    return {
      dialogSystemPopVisible: false,
      systemList: [
        {
          url: require('@/assets/fourWheels.png'),
          name: '新能源汽车充电管理平台',
          type: 0
        },
        {
          url: require('@/assets/twoWheels.png'),
          name: '两轮电瓶车充电管理平台',
          type: 1
        }
      ]
    }
  },
  methods: {
    openSystem(type) {
      this.$emit('openSystem', type);
      this.$store.commit('bizType', type);
      localStorage.setItem("bizType", type);
      axios.defaults.headers['bizType'] = type;
      this.$router.push({
        path: '/index'
      });
    }
  }
}
</script>

<style lang="less" scoped>
.center-flex() {
  display: flex; // 确保使用flex布局
  align-items: center;
  justify-content: center;
}
.systemSelectsPop {
  height: 500px;
  width: 100%;
  position: relative;
  background: url("~@/assets/systemSelectsPop.png") no-repeat center center;

  p {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 33px;
    font-weight: inherit;
    color: #165DFF;
  }

  .card-body {
    .center-flex();
    height: 100%;
    gap: 70px;
  }

  .card {
    width: 300px;
    height: 250px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    cursor: pointer;

    img {
      flex: 8;
      transition: transform 0.2s ease; /* 添加过渡效果，使放大平滑 */

      &:hover {
        transform: scale(1.1); /* 鼠标移入时放大到 1.1 倍 */
      }
    }

    span {
      .center-flex();
      flex: 2; /* 根据你的需求设置 flex 属性 */
      font-size: 14px;
      font-weight: bold;
      color: black;
    }
  }
}

/deep/ .el-dialog__header {
  display: none;
}

/deep/ .el-dialog__body {
  height: 100% !important;
}
</style>
