export default {
    computed:{
        percentage(){
            return this.$store.state.percentage;
        },
        showPercentage(){
            return this.$store.state.showPercentage;
        }
    },
    data(){
        return{
            msg: '正在上传中，请勿关闭当前窗口'
        }
    }
}
