<template>
    <div class="msg-wrap">
        <el-popover
            v-model="visible"
            placement="bottom-end"
            width="420"
            trigger="click"
            :visible-arrow="false"
            popper-class="msg-popover"
            :offset="16"
            >
            <div class="msg-box">
                <div class="msg-head">未读消息</div>
                <div class="msg-body">
                    <div class="msg-item" v-for="item in noticeList" :key="item.type">
                        <div class="text">【待{{item.type | typeFilter}}】 有<span class="mark">{{item.num}}</span>条{{item.type | typeFilter}}任务未处理</div>
                        <div class="btn" @click="noticeHandler(item.type)">立即处理</div>
                    </div>
                    <div class="msg-item nodata" v-if="!noticeList.length">
                        <div class="text">暂无新消息</div>
                    </div>
                </div>
            </div>
            <el-badge slot="reference" :value="noticeNumber" :max="99">
                <i class="el-icon-bell"></i>
            </el-badge>
        </el-popover>
    </div>
</template>

<script>
import axios from 'axios'
import fdEventBus from '@/js/common/fdEventBus.js'
export default{
    data(){
        return {
            noticeNumber: '',
            noticeList: [],
            visible: false
        }
    },
    created(){
        this.getNoticeList()
        fdEventBus.$on('refreshNoticeList', this.getNoticeList);
    },
    beforeDestroy() {
        fdEventBus.$off('refreshNoticeList');
    },
    filters:{
        typeFilter(val){
            return val == 1? '开票' : '退款'
        }
    },
    computed: {
        menuList(){
            return this.$store.state.commonUserInfoTree
        }
    },
    methods:{
        noticeHandler(type){
            let refundItem = null, refundItemc = null;
            let invoiceItem = null, invoiceItemc = null;
            let approvalManagementMenus = this.menuList.find(v=>v.key === 'approvalManagement')
            this.$store.commit('currentTopTab', approvalManagementMenus);
            fdEventBus.$emit('setLeftNavData', approvalManagementMenus, undefined);
            if(approvalManagementMenus){
                let children = approvalManagementMenus.children
                if(children && children.length){
                    if(type == 1){
                        invoiceItem = children.find(v=>v.key === 'applyForInvoice')
                        if(invoiceItem){
                            if(invoiceItem.children && invoiceItem.children.length){
                                invoiceItemc = invoiceItem.children.find(v=> v.key === 'waitInvoice')
                                if(invoiceItem && invoiceItemc){
                                    fdEventBus.$emit('changeLeftTabHandler', invoiceItem, invoiceItemc);
                                    fdEventBus.$emit('jumpRoutTrigger', invoiceItemc, 1);
                                }
                            }
                        }
                    }
                    else if(type == 2){
                        refundItem = children.find(v=>v.key === 'refundRequest')
                        if(refundItem){
                            if(refundItem.children && refundItem.children.length){
                                refundItemc = refundItem.children.find(v=> v.key === 'pendingApproval')
                                if(refundItem && refundItemc){
                                    fdEventBus.$emit('changeLeftTabHandler', refundItem, refundItemc);
                                    fdEventBus.$emit('jumpRoutTrigger', refundItemc, 1);
                                }
                            }
                        }
                    }
                }
            }
            this.visible = false
        },
        getNoticeList(){
            const url = window.fdConfig.url.globalNotice("globalNoticeApi");
            axios({
                method: 'GET',
                url
            })
            .then(res => {
                let result = res.data.data
                this.noticeNumber = result.total
                this.noticeList = result.notices || []
            })
            .catch((err) => {
                this.$message({
                    message: err.msg,
                    type: 'error',
                    duration: 2000,
                    showClose: true
                })
            })
        }
    }
}
</script>
<style lang="less" scoped>
    .msg{
        &-wrap{
            .el-icon-bell{
                color: #fff;
                font-size: 24px;
                font-weight: bold;
                cursor: pointer;
            }
        }
        &-box{
            user-select: none;
        }
        &-head{
            line-height: 40px;
            padding-left: 20px;
            font-size: 18px;
            color: #000;
            border-bottom: 1px solid #F3F3F3;
        }
        &-body{
            padding: 0 20px;
            max-height: 230px;
            overflow: auto;
            .msg-item{
                display: flex;
                justify-content: space-between;
                margin-top: 15px;
                &.nodata{
                    .text{
                        color: #909090;
                    }
                }
            }

            .text{
                width: 80%;
                font-size: 16px;
                color: #3D3D3D;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                .mark{
                    font-size: 18px;
                    font-weight: bold;
                    color: #FF5252;
                }
            }
            .btn{
                font-size: 16px;
                color: #2BB96B;
                cursor: pointer;
            }
        }
    }
</style>
<style lang="less">
    .msg-popover{
        padding: 10px 0 30px;
        border-radius: 16px;
    }
</style>
