import { SrsRtcPlayerAsync } from '../../../plugin/srs/srs.sdk.js';
export default {
    name: 'index',
    components:{SrsRtcPlayerAsync},
    props: ['stream'],
    data() {
            return {
                webrtcSdk: null,
                streamData: null // 视频流
        }
    },
    destroyed() {
        console.log('webrtcSdk destroyed')
        this.webrtcSdk.close();
    },
    mounted() {
        if (this.stream) {
            this.streamData = this.stream;
        }
        this.replay();
    },
    methods: {

        // 播放
        replay() {
            if (this.streamData !== '' && this.streamData !== null) {
                if (this.webrtcSdk) {
                    this.webrtcSdk.close();
                }
                this.webrtcSdk = new SrsRtcPlayerAsync();

                this.$refs.webrtc.srcObject = this.webrtcSdk.stream;

                this.webrtcSdk.play(this.streamData).then(function () {
                }).catch(function (reason) {
                    this.webrtcSdk.close();
                    console.error(reason);
                });
            }
        },
        /**
         * 切换播放源
         */
        changeSrc() {
            this.replay(this.webrtcSdk);
        }
    }
}
