import lodash from 'lodash';

export default {
    name: "index",
    data() {
        return {
            activeNumber: 0,
            navTabList: [],
            debounceTimeout: null,
            extendData: {
                children: [],
                key: "selectLiData",
                menuId: "selectLiData",
                menuType: 0,
                name: "更多",
                tier: 1,
            }
        }
    },
    props: {
        tabList: {
            type: Array,
            default: [
                {
                    name: '设备管理',
                    key: '123'
                }
            ]
        }
    },
    watch: {
        'tabList.length': {
            deep: true,  // 深度监听
            handler(newValue, oldValue) {
                this.navTabList = this.tabList;
                // 获取当前ul的右边的宽度
                this.changeNavShowType();

            }
        }
    },
    methods: {
        // 点击tab事件向外传递  selectFlag: 代表是都是从更多中选中tab
        changeTabHandler(item, index, selectFlag) {
            // 当前点击的tab为更多不可以继续操作
            if (item.key === 'selectLiData'){
                return;
            }
            // 由于分为两个ul循环展示tab，index会出现错误，第二个ul中li重新计算了index，此步骤是将index接回原来tablist的index上
            index = selectFlag ? (this.navTabList.length - 1) + index : index;
            // 后续传递事件
            this.$emit('changeTabHandler', item, index);
        },
        // 改变index事件，外向内传递数据
        changeinsideTab(index) {
            this.activeNumber = index;
        },
        // 改变视窗实时更换导航展示状态
        changeNavShowType() {
            this.navTabList = lodash.cloneDeep(this.tabList);
            this.$nextTick(() => {
                // 获取ul右侧极限到视窗x轴起点的距离
                const ulWidth = document.getElementById('com_ul').getBoundingClientRect().right;
                // 获取当前li个数的真实跨度距离
                const realWidth = this.tabList.length * 107 + 227
                // li在没遮挡的情况下的长度 - ul实际长度 = 遮盖的长度
                const overNumber = realWidth - ulWidth
                // 为正数的时候说明已经隐藏了li了
                if (overNumber > 0) {
                    // 获取当前可展示几个li
                    const canShowLi = Math.trunc((ulWidth - 227) / 107)
                    // console.log(canShowLi);
                    // 由于数据是父组件传递过来，单向数据流的原则需要深拷贝一份数据
                    const data = lodash.cloneDeep(this.tabList);
                    // 需要进行下拉的数据
                    let selectData = data.splice(canShowLi - 1, this.tabList.length - canShowLi + 1);
                    // 将"更多" push 进data中
                    data.push(this.extendData)
                    // 回显tab
                    this.navTabList = data;
                    // 更多tab中的孩子赋值为可选中的，被分割的节点数组
                    this.extendData.children = selectData;

                }
            })
        },
        // 防抖
        debounce(fn, wait) {
            if (this.debounceTimeout !== null) {
                clearTimeout(this.debounceTimeout);
                this.debounceTimeout = null;
            }
            this.debounceTimeout = setTimeout(fn, wait);
        }
    },
    mounted() {
        this.$emit('ready');
    },
    created() {
        let _this = this;
        // 窗口变化
        window.addEventListener("resize", () => {
            _this.debounce(_this.changeNavShowType, 100)
        });
    }
}
