
// 服务器配置
import serverUrl from './server-config.js';
// let production = process.env.NODE_ENV !== 'production'
// 默认开发环境
const develop = false;
//
const config = {
    componentPrefix: 'component-',
    classPrefix: 'fd-',
    //  是否是调试模式，true 为json数据，false 为服务器数据
    isDebug: develop,
    // ajax查询方式   GET
    methodGet: 'GET',
    // ajax查询方式  POST
    methodPost: develop ? 'GET' : 'POST',
    // ajax查询方式  PUT
    methodPut: develop ? 'GET' : 'PUT',
    // ajax查询方式  DELETEsocketio
    methodDelete: develop ? 'GET' : 'DELETE',
    // ajax查询方式  PATCH
    methodPatch: develop ? 'GET' : 'PATCH',
    //  url链接,也就是数据的地址
    url: {},
    // 是否显示日志
    showLog: false
    // socketUrl: window.serverConfig.socketUrl, // socket io连接地址
    // sz: window.serverConfig.sz, // 收转地址
};
// 本地数据
const localUrl = {
    // 分册模板 【Fc Controller 】
    booklets: {
        checkPdfProtocol: '/static/json/booklet/check-pdf-protocol.json',
    }
};
config.url = config.isDebug ? localUrl : serverUrl;
//  注册全局变量，fdConfig
window.fdConfig = config;
