/**
 * @author  wuwg
 * @createTime  2018-09-10
 * @updateTime  2018-09-10
 * @description  公用组件
 */
// vue框架
import Vue from 'vue';
// basic start
import topNav from '@/components/common/top-nav/index.vue';
import leftNav from '@/components/common/left-nav/index.vue';
import insideTab from '@/components/common/inside-tab/index.vue';
import videoPlay from '@/components/common/index-video/index.vue';
import crumbsSlide from '@/components/common/index-crumbsSlide/index.vue';
import indexSchedule from '@/components/common/index-schedule/index.vue';


const components = {
    topNav,
    leftNav,
    insideTab,
    videoPlay,
    crumbsSlide,
    indexSchedule
};

// 安装
const install = function (Vue) {
    if (install.installed) {
        return false;
    }
    let i = 0;
    // 注册组件
    Object.keys(components).forEach(key => {
        i++;
        const pattern = /([A-Z])|^(\$)/gm;
        let _key = key;
        // 大写转-小写
        _key = _key.replace(pattern, function (a) {
            // ==$ 表示是因为组件是关键词，所以多加了标识符$
            return a === '$' ? '' : `-${a.toLowerCase()}`;
        });
        Vue.component(`component-${_key}`, components[key]);
    });

    /**
     Vue.prototype.$Notice = notice
     Vue.prototype.$Spin = spin
     Vue.prototype.$LoadingBar = loadingBar
     */
    console.log(`组件总数${i}`);
};
const API = {
    install
};
Vue.use(API);
