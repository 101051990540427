// 一套默认主题以及一套暗黑主题
export const themes = {
    fourWheels: {
        baseColor: `#169D61`, // 基色（无变化）
        fontColor: `#0BB63B`, // 页面的背景色
        iconBgColor: `#C9F2E0`, // 滚动条的背景色
        tableCellColor: `#E5F7EE`, // 结果背景色
        hoverTreeColor: `#C5FAE1`, // 结果区背景色
        activeTextColor: `#209F76`, // 结果文字
        hoveTextColor: `#49B582`, // 结果文字
        navBgColor: `linear-gradient(93deg, #169D61, #2EBC6D)`
    },
    twoWheels: {
        baseColor: `#409EFF`, // 基色（无变化）
        fontColor: `#165DFF`, // 页面的背景色
        iconBgColor: `#C8E1FF`, // 滚动条的背景色
        hoverTreeColor: `#C8E1FF`, // 结果背景色
        tableCellColor: `#E4F1FF`, // 结果区背景色
        activeTextColor: `#165DFF`, // 结果文字
        hoveTextColor: `#409EFF`, // 结果文字
        navBgColor: `linear-gradient(90deg, #165DFF 0%, #409EFF 100%, #24B967 100%)`


// @two-wheels:;
    },
};
